<template>
  <div>
    <p class="mb-3">
      For molecules with a
      <stemble-latex :content="geometry" />
      geometry, what would be the hybridization of the central atom?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question243b',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: '$\\text{sp}^3\\text{d}$', value: 'sp3d'},
        {expression: '$\\text{sp}^2$', value: 'sp2'},
        {expression: '$\\text{sp}^3\\text{d}^3$', value: 'sp3d3'},
        {expression: '$\\text{sp}^3$', value: 'sp3'},
        {expression: '$\\text{sp}^3\\text{d}^2$', value: 'sp3d2'},
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          // 1 = trigonal bipyramidal
          return {
            geometry: 'trigonal bipyramidal',
          };
        case 2:
          // 2 = t-shape
          return {
            geometry: 't-shape',
          };
        case 3:
          // 3 = seesaw
          return {
            geometry: 'see-saw',
          };
        case 4:
          // 4 = octahedral
          return {
            geometry: 'octahedral',
          };
        case 5:
          // 5 = square pyramidal
          return {
            geometry: 'square pyramidal',
          };
        case 6:
          // 6 = square planar
          return {
            geometry: 'square planar',
          };
        default:
          return {
            geometry: null,
          };
      }
    },
    geometry() {
      return this.versionData.geometry;
    },
  },
};
</script>
